<template>
    <b-modal
        id="hospitalization_request_guide_modal"
        hide-header
        hide-footer
        centered size="xl"
        @hidden="closeModal"
        @show="onShow"
    >
        <div class="modal-header">
            <div class="modal-title">
              <span v-if="tissGuide?.id">Atualizar guia de solicitação internação - Nº {{ form.provider_guide }}</span>
              <span v-else>Nova guia de solicitação internação - Nº {{ form.provider_guide }}</span>
            </div>
            <span class="icon-box"><Close class="icon stroke" @click="closeModal"  /></span>
        </div>

        <div class="around-content">
          <GuideDataModule
            :form="form"
            :fields="{tiss_invoice_id : false, situation : true}"
            :validated="validated"
            :readonly="readonly"
            :healthPlanId="healthPlan ? healthPlan.id : null"
            :tissBatchId="tissGuide.tiss_batch_id"
            :tissGuide="tissGuide"
          />

          <b-row>
              <b-col cols="12">
                  <p class="form-title mb-3">Dados da operadora</p>
              </b-col>

              <b-col cols="4">
                  <label for="ans">1 - Registro ANS</label>
                  <b-form-input
                    maxlength="6"
                    v-model="form.ans"
                  />
              </b-col>

              <b-col cols="4">
                <label for="provider_guide">2 - Nº da guia do prestador</label>
                <ProviderGuideInput
                  :providerGuide="form.provider_guide"
                  :validateProviderGuide="validateProviderGuide"
                  type="hospitalization_request"
                  :validated="validated"
                  :guideNumberExistsInBatch="guideNumberExistsInBatch"
                  @update:provider-guide="form.provider_guide = $event"
                />
              </b-col>

              <b-col cols="4">
                  <label for="operator_guide_number">3 - Nº da guia Atribuído pela Operadora</label>
                  <b-form-input
                    maxlength="20"
                    placeholder="Descrever"
                    autocomplete="off"
                    v-model="form.operator_guide_number"
                    :readonly="readonly"
                  />
                  <div v-if="validated && (!form.operator_guide_number && required_fields.guide_number_hospitalization)" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4" class="mt-3">
                  <label for="authorization_date">4 - Data da autorização</label>
                  <date-picker
                    :lang="langDatePicker"
                    placeholder="Selecionar"
                    v-model="form.authorization_date"
                    format="DD/MM/YYYY"
                    :clearable="false"
                    class="full"
                    :disabled="readonly"
                  />
                  <div v-if="validated && (!form.authorization_date && required_fields.date_hospitalization)" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4" class="mt-3 input-password">
                <label for="password">5 - Senha</label>
                <b-input-group>
                  <b-form-input
                    maxlength="20"
                    placeholder="Descrever"
                    autocomplete="off"
                    type="text"
                    v-model="form.password"
                    :readonly="readonly"
                  />
                </b-input-group>
                <div v-if="validated && (!form.password && required_fields.password_hospitalization)" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4" class="mt-3">
                  <label for="password_expiration_date">6 - Validade da senha</label>
                  <date-picker
                    :lang="langDatePicker"
                    placeholder="Selecionar"
                    v-model="form.password_expiration_date"
                    format="DD/MM/YYYY"
                    :clearable="false"
                    class="full"
                    :disabled="readonly"
                  />
                  <div v-if="validated && (!form.password && required_fields.expiration_date_hospitalization)" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="12">
                  <hr class="hr" />
              </b-col>
          </b-row>

          <b-row>
              <b-col cols="12">
                  <p class="form-title mb-3">Dados do beneficiário (Paciente)</p>
              </b-col>

              <b-col cols="5">
                  <label for="wallet_number">7 - Nº da carteira</label>
                  <b-form-input
                      maxlength="20"
                      placeholder="Descrever"
                      autocomplete="off"
                      v-model="form.wallet_number"
                      :readonly="readonly"
                  />
                  <div v-if="validated && !form.wallet_number" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="5">
                  <label for="wallet_expiration_date">8 - Validade da carteira</label>
                  <date-picker
                    :lang="langDatePicker"
                    placeholder="Selecionar"
                    v-model="form.wallet_expiration_date"
                    format="DD/MM/YYYY"
                    :clearable="false"
                    class="full"
                    :disabled="readonly"
                  />
                  <div v-if="validated && (!form.wallet_expiration_date && required_fields.purse_expiration_date)" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="2">
                  <label for="newborn_care">9 - Recém-nascido</label>
                  <div class="mt-3">
                    <label class="checkbox" align="center">
                      <input
                        type="checkbox"
                        v-model="form.newborn_care"
                        :disabled="readonly || (patient && patient.birthday)"
                      />
                      <span class="checkmark" :class="{'check-disable': (readonly || (patient && patient.birthday))}">
                        <Check />
                      </span>
                    </label>
                  </div>
              </b-col>

              <b-col cols="6" class="mt-3">
                  <label for="beneficiary_name">10 - Nome do beneficiário</label>
                  <b-form-input
                    maxlength="20"
                    placeholder="Descrever"
                    autocomplete="off"
                    v-model="form.beneficiary_name"
                    :readonly="true"
                  />
                  <div v-if="validated && !form.beneficiary_name" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="6" class="mt-3">
                  <label for="national_health_card">11 - Cartão Nacional de Saúde</label>
                  <b-form-input
                      maxlength="15"
                      placeholder="Descrever"
                      autocomplete="off"
                      v-model="form.national_health_card"
                      :readonly="readonly"
                  />
                  <div v-if="validated && !form.national_health_card" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="12">
                  <hr class="hr" />
              </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <p class="form-title mb-3">Dados do Contratado Solicitante</p>
            </b-col>

            <ContractedField
              label="Nome do Contratado"
              id="contractor_name"
              :readonly="readonly"
              :vModel="form.contractor_name"
              :validated="validated"
              :contracteds="contracteds"
              @select-contracted="selectContracted"
            />

            <b-col cols="4">
              <label for="contracted_code_type">Tipo do código contratado</label>
              <multiselect
                v-model="form.contracted_code_type"
                placeholder="Selecionar"
                :options="contractedCodeTypes"
                track-by="value"
                label="label"
                :allow-empty="false"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
                :disabled="readonly"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>
                <template slot="noOptions">
                  Nenhuma opção
                </template>
                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>
              <div v-if="validated && !form.contracted_code_type" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="3">
              <label for="operator_code">Código na Operadora</label>
              <b-form-input
                placeholder="Descrever"
                autocomplete="off"
                v-model="form.operator_code"
                :readonly="readonly"
                v-mask="mask"
              />
              <div v-if="validated && !form.operator_code" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="3" class="mt-3">
              <label for="professional_name_requesting">14 - Nome prof. solicitante</label>
              <ProfessionalFieldModule
                :settingsId="form.invoicing_tiss_setting_id"
                :form="form"
                :multiselect="true"
                :readonly="readonly"
                :fillProfessionalData="fillProfessionalData"
                @change-professionals="professionals = $event"
              />
              <div v-if="validated && !form.professional_selected" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="2" class="mt-3">
              <label for="professional_council">15 - Conselho</label>
              <multiselect
                v-model="form.professional_council"
                placeholder="Selecionar"
                :options="professionalCouncils"
                track-by="value"
                label="label"
                :allow-empty="false"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
                disabled
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                  <span class="d-inline-block text-truncate width-80">
                    {{ option.label }}
                  </span>
                </template>
                <template slot="noOptions">
                  Nenhuma opção
                </template>
                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>
              <div v-if="validated && !form.professional_council" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="3" class="mt-3">
              <label for="professional_council_registration">16 - Nº do conselho</label>
              <b-form-input
                maxlength="15"
                placeholder="Descrever"
                autocomplete="off"
                v-model="form.professional_council_registration"
                readonly
              />
              <div v-if="validated && !form.professional_council_registration" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="2" class="mt-3">
              <label for="uf">17 - UF</label>
              <multiselect
                v-model="form.uf"
                placeholder="Selecionar"
                :options="statesUf"
                track-by="value"
                label="label"
                :allow-empty="false"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
                disabled
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>
                <template slot="noOptions">
                  Nenhuma opção
                </template>
                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>
              <div v-if="validated && !form.uf" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="2" class="mt-3">
              <label for="cbo_code">18 - CBO</label>
              <multiselect
                v-model="form.cbo_code"
                placeholder="Selecionar"
                :options="cbos"
                track-by="value"
                label="label"
                :allow-empty="false"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
                disabled
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                  <template slot="singleLabel" slot-scope="{ option }">
                    <span class="d-inline-block text-truncate width-80">
                      {{ option.label }}
                    </span>
                  </template>

                  <template slot="noOptions">
                    Nenhuma opção
                  </template>

                  <template slot="noResult">
                    Nenhum resultado
                  </template>
                </multiselect>
              <div v-if="validated && !form.cbo_code" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="12">
              <hr class="hr" />
            </b-col>
          </b-row>

          <b-row>
              <b-col cols="12">
                  <p class="form-title mb-3">Dados do Hospital / Local Solicitado / Dados da internação</p>
              </b-col>

              <b-col cols="3">
                  <label for="operator_code_cnpj">19 - Cod. na operadora/CNPJ</label>
                  <b-form-input
                      maxlength="14"
                      autocomplete="off"
                      placeholder="Descrever"
                      v-model="form.operator_code_cnpj"
                      :readonly="readonly"
                  />
                  <div v-if="validated && !form.operator_code_cnpj" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="5">
                  <label for="hospital_name">20 - Nome do Hospital / Local Solicitado</label>
                  <b-form-input
                      maxlength="70"
                      autocomplete="off"
                      placeholder="Descrever"
                      v-model="form.hospital_name"
                      :readonly="readonly"
                  />
                  <div v-if="validated && !form.hospital_name" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4">
                  <label for="suggested_date_hospitalization">21 - Data sugerida p/ internação</label>
                  <date-picker
                    :lang="langDatePicker"
                    placeholder="Selecionar"
                    v-model="form.suggested_date_hospitalization"
                    format="DD/MM/YYYY"
                    :clearable="false"
                    class="full"
                    :disabled="readonly"
                  />
                  <div v-if="validated && !form.suggested_date_hospitalization" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4" class="mt-3">
                  <label for="attendance_character">22 - Caráter do Atendimento</label>
                  <multiselect
                    v-model="form.attendance_character"
                    placeholder="Selecionar"
                    :options="attendancesCharacter"
                    track-by="value"
                    label="label"
                    :allow-empty="false"
                    :showLabels="false"
                    :showNoResults="false"
                    class="with-border"
                    :disabled="readonly"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.label }}
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>
                  <div v-if="validated && !form.attendance_character" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4" class="mt-3">
                  <label for="hospitalization_type">23 - Tipo de internação</label>
                  <multiselect
                    v-model="form.hospitalization_type"
                    placeholder="Selecionar"
                    :options="hospitalizationTypes"
                    track-by="value"
                    label="label"
                    :allow-empty="false"
                    :showLabels="false"
                    :showNoResults="false"
                    class="with-border"
                    :disabled="readonly"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.label }}
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>
                  <div v-if="validated && !form.hospitalization_type" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4" class="mt-3">
                  <label for="internment_regime">24 - Regime de internação</label>
                  <multiselect
                    v-model="form.internment_regime"
                    placeholder="Selecionar"
                    :options="internmentRegimes"
                    track-by="value"
                    label="label"
                    :allow-empty="false"
                    :showLabels="false"
                    :showNoResults="false"
                    class="with-border"
                    :disabled="readonly"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.label }}
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>
                  <div v-if="validated && !form.internment_regime" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4" class="mt-3">
                <label for="requested_daily">25 - Qtde. Díarias Solicitadas</label>
                  <multiselect
                    v-model="form.requested_daily"
                    placeholder="Selecionar"
                    :options="quantities"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :internal-search="false"
                    class="with-border"
                    :disabled="readonly"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                <div v-if="validated && !form.requested_daily" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4" class="mt-3">
                  <label for="opme_usage_forecast">26 - Uso de OPME</label>
                  <multiselect
                    v-model="form.opme_usage_forecast"
                    placeholder="Selecionar"
                    :options="yesOrNo"
                    track-by="value"
                    label="label"
                    :allow-empty="false"
                    :showLabels="false"
                    :showNoResults="false"
                    class="with-border"
                    :disabled="readonly"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.label }}
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>
                  <div v-if="validated && !form.opme_usage_forecast" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="4" class="mt-3">
                  <label for="prediction_chemotherapy_use">27 - Uso de quimioterápico</label>
                  <multiselect
                    v-model="form.prediction_chemotherapy_use"
                    placeholder="Selecionar"
                    :options="yesOrNo"
                    track-by="value"
                    label="label"
                    :allow-empty="false"
                    :showLabels="false"
                    :showNoResults="false"
                    class="with-border"
                    :disabled="readonly"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.label }}
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>
                  <div v-if="validated && !form.prediction_chemotherapy_use" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="12" class="mt-3">
                <label for="clinical_indication">28 - Indicação clínica</label>
                <b-form-input
                  maxlength="500"
                  autocomplete="off"
                  placeholder="Descrever"
                  v-model="form.clinical_indication"
                  :readonly="readonly"
                />
                <div v-if="validated && !form.clinical_indication" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="3" class="mt-3">
                <label for="cid_1_selected">29 - CID 10 Principal (1)</label>
                <multiselect
                  v-model="form.cid_1_selected"
                  id="cid_1_selected"
                  :options="cids"
                  label="label"
                  track-by="value"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="false"
                  @search-change="debounceGetCids"
                  @select="selectCid10"
                  placeholder="Selecionar"
                  class="with-border"
                  :disabled="readonly"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>

                  <template slot="singleLabel" slot-scope="{ option }">
                    <span class="d-inline-block text-truncate width-80">
                      {{ option.label }}
                    </span>
                  </template>

                  <template slot="noOptions">
                    Digite para pesquisar um CID10
                  </template>
                  <template slot="noResult">Nenhum CID10 encontrado</template>
                </multiselect>
              </b-col>

              <b-col cols="2" class="mt-3">
                <label for="cid_2_selected">30 - CID 10 (2)</label>
                <multiselect
                  v-model="form.cid_2_selected"
                  id="cid_2_selected"
                  :options="cids"
                  label="label"
                  track-by="value"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="false"
                  @search-change="debounceGetCids"
                  @select="selectCid10"
                  placeholder="Selecionar"
                  class="with-border"
                  :disabled="readonly"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>

                  <template slot="singleLabel" slot-scope="{ option }">
                    <span class="d-inline-block text-truncate width-80">
                      {{ option.label }}
                    </span>
                  </template>

                  <template slot="noOptions">
                    Digite para pesquisar um CID10
                  </template>
                  <template slot="noResult">Nenhum CID10 encontrado</template>
                </multiselect>
              </b-col>

              <b-col cols="2" class="mt-3">
                <label for="tertiary_cid_10">31 - CID 10 (3)</label>
                <multiselect
                  v-model="form.cid_3_selected"
                  id="cid_3_selected"
                  :options="cids"
                  label="label"
                  track-by="value"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="false"
                  @search-change="debounceGetCids"
                  @select="selectCid10"
                  placeholder="Selecionar"
                  class="with-border"
                  :disabled="readonly"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>

                  <template slot="singleLabel" slot-scope="{ option }">
                    <span class="d-inline-block text-truncate width-80">
                      {{ option.label }}
                    </span>
                  </template>

                  <template slot="noOptions">
                    Digite para pesquisar um CID10
                  </template>
                  <template slot="noResult">Nenhum CID10 encontrado</template>
                </multiselect>
              </b-col>

              <b-col cols="2" class="mt-3">
                <label for="cid_4_selected">32 - CID 10 (4)</label>
                <multiselect
                  v-model="form.cid_4_selected"
                  id="cid_4_selected"
                  :options="cids"
                  label="label"
                  track-by="value"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="false"
                  @search-change="debounceGetCids"
                  @select="selectCid10"
                  placeholder="Selecionar"
                  class="with-border"
                  :disabled="readonly"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>

                  <template slot="singleLabel" slot-scope="{ option }">
                    <span class="d-inline-block text-truncate width-80">
                      {{ option.label }}
                    </span>
                  </template>

                  <template slot="noOptions">
                    Digite para pesquisar um CID10
                  </template>
                  <template slot="noResult">Nenhum CID10 encontrado</template>
                </multiselect>
              </b-col>

              <b-col cols="3" class="mt-3">
                  <label for="accident_indication">33 - Indicação de acidente</label>
                  <multiselect
                    v-model="form.accident_indication"
                    placeholder="Selecionar"
                    :options="accidentIndications"
                    track-by="value"
                    label="label"
                    :allow-empty="false"
                    :showLabels="false"
                    :showNoResults="false"
                    class="with-border"
                    :disabled="readonly"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.label }}
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>
                  <div v-if="validated && !form.accident_indication" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="12">
                  <hr class="hr" />
              </b-col>
          </b-row>

          <ProceduresRequestsModule
            :materialsProcedures="form.materialsProcedures"
            guide="hospitalization-request"
            :settingsId="form.invoicing_tiss_setting_id"
            :readonly="readonly"
          />

          <b-row>
            <b-col cols="12">
              <p class="form-title mb-3">Dados da Autorização</p>
            </b-col>

              <b-col cols="4">
                <label for="probable_date_hospital_admission">39 - Data Prov. Admissão Hospitalar</label>
                <date-picker
                  :lang="langDatePicker"
                  placeholder="Selecionar"
                  v-model="form.probable_date_hospital_admission"
                  format="DD/MM/YYYY"
                  :clearable="false"
                  class="full"
                  :disabled="readonly"
                />
              </b-col>

              <b-col cols="4">
              <label for="authorized_days">40 - Qtde. Díarias Autorizadas</label>
                <multiselect
                  v-model="form.authorized_days"
                  placeholder="Selecionar"
                  :options="quantities"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="false"
                  class="with-border"
                  :disabled="readonly"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
              </b-col>

              <b-col cols="4">
                <label for="authorized_accommodation_type">41 - Tipo da acomodação autorizada</label>
                <multiselect
                  v-model="form.authorized_accommodation_type"
                  placeholder="Selecionar"
                  :options="accommodationsType"
                  track-by="value"
                  label="label"
                  :allow-empty="false"
                  :showLabels="false"
                  :showNoResults="false"
                  class="with-border"
                  :disabled="readonly"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>

                  <template slot="singleLabel" slot-scope="{ option }">
                    <span class="d-inline-block text-truncate width-95">
                      {{ option.label }}
                    </span>
                  </template>

                  <template slot="noOptions">
                    Nenhuma opção
                  </template>

                  <template slot="noResult">
                    Nenhum resultado
                  </template>
                </multiselect>
              </b-col>

              <b-col cols="5" class="mt-3">
                <label for="operator_authorized_code_cnpj">42 - Cod. na operador / CNPJ Autorizado</label>
                <b-form-input
                    autocomplete="off"
                    placeholder="Descrever"
                    v-model="form.operator_authorized_code_cnpj"
                    :readonly="readonly"
                />
              </b-col>

              <b-col cols="5" class="mt-3">
                <label for="hospital_name_authorized">43 - Nome do Hospital / Local Solicitado</label>
                <b-form-input
                  maxlength="70"
                  autocomplete="off"
                  placeholder="Descrever"
                  v-model="form.hospital_name_authorized"
                  :readonly="readonly"
                />
              </b-col>

              <b-col cols="2" class="mt-3">
                <label for="cnes_code_authorized">44 - Código CNES</label>
                <b-form-input
                  maxlength="7"
                  placeholder="Descrever"
                  autocomplete="off"
                  v-model="form.cnes_code_authorized"
                  :readonly="readonly"
                />
              </b-col>

              <b-col cols="9" class="mt-3">
                <label for="observation">45 - Observação / Justificativa</label>
                <b-form-input
                  placeholder="Descrever"
                  autocomplete="off"
                  v-model="form.observation"
                  :readonly="readonly"
                />
              </b-col>

              <b-col cols="3" class="mt-3">
                <label for="request_date">46 - Data da solicitação</label>
                <date-picker
                  :lang="langDatePicker"
                  placeholder="Selecionar"
                  v-model="form.request_date"
                  format="DD/MM/YYYY"
                  :clearable="false"
                  class="full"
                  :disabled="readonly"
                />
                <div v-if="validated && !form.request_date" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>
          </b-row>

          <div class="wrapper-button">
            <b-button
              class="wh-button mr-2"
              variant="outline-primary"
              outline
              v-if="tissGuide?.id"
              @click="print"
            >
              Imprimir guia
            </b-button>

            <b-button
              v-if="tissGuide && tissGuide.id && !readonly"
              variant="primary"
              size="lg"
              @click="updateGuide"
            >
              Atualizar guia
            </b-button>

            <b-button
              v-else-if="!readonly"
              variant="primary"
              size="lg"
              @click="createGuide"
            >
              Salvar guia
            </b-button>
          </div>
        </div>
    </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import debounce from 'lodash/debounce'
import professionalFieldMixin from '@/mixins/professionalFieldMixin'

export default {
  name: 'HospitalizationRequestGuideModal',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Check: () => import('@/assets/icons/check.svg'),
    GuideDataModule: () => import('./Modules/GuideDataModule'),
    ProfessionalFieldModule: () => import('./Modules/ProfessionalFieldModule'),
    ProceduresRequestsModule: () => import('./Modules/MaterialsProceduresModule'),
    ProviderGuideInput: () => import('@/components/Tiss/Generic/ModalComponents/ProviderGuideInput'),
    ContractedField: () => import('@/components/Tiss/Guides/Modules/ContractedField'),
  },
  props: {
    patient: Object,
    healthPlan: Object,
    invoicing_tiss_setting: Object,
    appointmentId: String,
    required_fields: Object,
    tissGuide: Object,
    guideNumber: Number,
    getHospitalizationGuidesNumbers: Function,
    providerGuideReadonly: Boolean,
    validateProviderGuide: Function,
    guideNumberExistsInBatch: Boolean,
    guideNumberExists: Boolean,
    providerGuideUpdated: Number,
    contracteds: Array
  },
  mixins: [professionalFieldMixin('form', 'tissGuide')],
  created() {
    this.debounceGetCids = debounce(this.searchCid10, 500)
  },
  computed: {
    readonly() {
      return Boolean(
        Object.keys(this.tissGuide).length
        && (['Finalizada'].includes(this.tissGuide.situation)|| this.tissGuide.tiss_batch_id)
      )
    },
    mask() {
      if (!this.form.contracted_code_type) return '##############'
      return {
        'CNPJ do contratado': "##.###.###/####-##",
        'CPF do contratado': "###.###.###-##",
        default: "##############"
      }[this.form.contracted_code_type.value] || "##############"
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      cids: [],
      contractedCodeTypes: require('./../Data/contractedCodeTypes.json'),
      professionalCouncils: require('./../Data/professionalCouncils.json'),
      statesUf: require('./../Data/statesUf.json'),
      cbos: require('./../Data/cbos.json'),
      attendancesCharacter: require('./../Data/attendancesCharacter.json'),
      hospitalizationTypes: require('./../Data/hospitalizationTypes.json'),
      internmentRegimes: require('./../Data/internmentRegimes.json'),

      validated: false,
      form: {},

      yesOrNo: [
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
      ],
      accidentIndications: require('./../Data/accidentIndications.json'),
      accommodationsType: require('./../Data/accommodationsType.json'),
      quantities: Array.from({length: 10}, (_, i) => i + 1),
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        },
      }
    }
  },
  methods: {
    onShow () {
      this.getPatientWallet()
    },
    getDefaultForm() {
      return {
        clinic_id: this.clinic.id,
        invoicing_tiss_setting_id: this.invoicing_tiss_setting ? this.invoicing_tiss_setting.id : null,
        appointment_id: this.appointmentId,
        id: null,
        tiss_guide_id: null,

        situation: null,
        tiss_invoice_id: null,
        bank_account_id: this.invoicing_tiss_setting ? this.invoicing_tiss_setting.bank_account_id: null,

        ans: this.healthPlan?.ans_code,
        provider_guide: this.guideNumber,
        operator_guide_number: null,

        authorization_date: null,
        password: null,
        password_expiration_date: null,

        wallet_number: null,
        wallet_expiration_date: null,
        beneficiary_name: this.patient && this.patient.name ? this.patient.name : null,
        national_health_card: this.patient && this.patient.cns ? this.patient.cns : null ,
        newborn_care: this.patient && this.patient.birthday ? (this.moment(this.patient.birthday).add(1, 'M') >=  this.moment()) : false,

        professional_selected: null,
        professional_id: null,

        contracted_code_type: null,
        operator_code: this.invoicing_tiss_setting && this.invoicing_tiss_setting.tiss_using_guide_type && this.invoicing_tiss_setting.tiss_using_guide_type.sp_sadt_guide ?
          this.invoicing_tiss_setting.hired_executant_code: null,
        contractor_name: this.invoicing_tiss_setting && this.invoicing_tiss_setting.tiss_using_guide_type && this.invoicing_tiss_setting.tiss_using_guide_type.sp_sadt_guide ?
          this.invoicing_tiss_setting.hired_executant_name: null,
        professional_name_requesting: null,
        professional_council: null,
        professional_council_registration: null,
        uf: null,
        cbo_code: null,

        operator_code_cnpj: null,
        hospital_name: null,
        suggested_date_hospitalization: null,
        attendance_character: null,
        hospitalization_type: null,
        internment_regime: null,
        requested_daily: null,
        opme_usage_forecast: null,
        prediction_chemotherapy_use: null,
        clinical_indication: null,
        cid_1: null,
        cid_2: null,
        cid_3: null,
        cid_4: null,

        accident_indication: null,

        probable_date_hospital_admission: null,
        authorized_days: null,
        authorized_accommodation_type: null,
        operator_authorized_code_cnpj: null,
        hospital_name_authorized: null,
        cnes_code_authorized: null,

        observation: null,
        request_date: null,
        materialsProcedures: []
      }
    },
    setSelectsFields() {
      if (this.tissGuide.id) return
      const fields = {
        contracted_code_type: this.invoicing_tiss_setting?.provider_code ?
          this.contractedCodeTypes.find(option => option.label === this.invoicing_tiss_setting.provider_code) : null,

        attendance_character: this.invoicing_tiss_setting?.attendance_character ?
          this.attendancesCharacter.find(option => option.label === this.invoicing_tiss_setting.attendance_character) : null,

        accident_indication: this.invoicing_tiss_setting?.accident_indication ?
          this.accidentIndications.find(option => option.label === this.invoicing_tiss_setting.accident_indication) : null,
      }

      this.form = {
        ...this.form,
        ...fields
      }
    },
    isValidForm() {
      if (this.form.situation !== 'Finalizada') return true;

      this.validated = true

      if (this.guideNumberExists) {
        this.$toast.error('Este Nº de guia já existe, resolva o conflito para continuar.')
        return false
      }

      const operatorGuideValidation = this.required_fields.guide_number_hospitalization ? !!this.form.operator_guide_number : true
      const authorizationDateValidation = this.required_fields.date_hospitalization ? !!this.form.authorization_date : true
      const passwordValidation = this.required_fields.password_hospitalization ? !!this.form.password : true
      const passwordExpirationValidation = this.required_fields.expiration_date_hospitalization ? !!this.form.password_expiration_date : true
      const walletExpirationValidation = this.required_fields.purse_expiration_date ? !!this.form.wallet_expiration_date : true

      return !this.guideNumberExistsInBatch &&
        (this.form.provider_guide && this.form.provider_guide > 0) &&
        operatorGuideValidation &&
        authorizationDateValidation &&
        passwordValidation &&
        passwordExpirationValidation &&
        this.form.wallet_number &&
        walletExpirationValidation &&
        this.form.beneficiary_name &&
        this.form.national_health_card &&
        this.form.contracted_code_type &&
        this.form.operator_code &&
        this.form.contractor_name &&
        this.form.professional_council &&
        this.form.professional_council_registration &&
        this.form.uf &&
        this.form.cbo_code &&
        this.form.operator_code_cnpj &&
        this.form.hospital_name &&
        this.form.suggested_date_hospitalization &&
        this.form.attendance_character &&
        this.form.hospitalization_type &&
        this.form.internment_regime &&
        this.form.requested_daily &&
        this.form.opme_usage_forecast &&
        this.form.prediction_chemotherapy_use &&
        this.form.clinical_indication &&
        this.form.accident_indication &&
        this.form.request_date
    },
    print() {
      this.$emit('print', this.tissGuide)
    },
    async searchCid10(query) {
      if (query.length > 2) {
        this.cids = []
        try {
          const response = await this.api.searchCid10({ term: query })
          this.cids = response.data.map(cid10 => {
            return {
              label: `${cid10?.code} - ${cid10.description}`,
              value: cid10.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    getSelectedValue(){
      if(typeof this.form.tiss_invoice_id === 'object') this.form.tiss_invoice_id = this.form?.tiss_invoice_id?.value ?? null
      if(typeof this.form.contracted_code_type === 'object') this.form.contracted_code_type = this.form.contracted_code_type?.value
      if(typeof this.form.professional_council === 'object') this.form.professional_council = this.form.professional_council?.value
      if(typeof this.form.uf === 'object') this.form.uf = this.form.uf?.value
      if(typeof this.form.cbo_code === 'object') this.form.cbo_code = this.form.cbo_code?.value
      if(typeof this.form.attendance_character === 'object') this.form.attendance_character = this.form.attendance_character?.value
      if(typeof this.form.hospitalization_type === 'object') this.form.hospitalization_type = this.form.hospitalization_type?.value
      if(typeof this.form.internment_regime === 'object') this.form.internment_regime = this.form.internment_regime?.value
      if(typeof this.form.opme_usage_forecast === 'object') this.form.opme_usage_forecast = this.form.opme_usage_forecast?.value
      if(typeof this.form.prediction_chemotherapy_use === 'object') this.form.prediction_chemotherapy_use = this.form.prediction_chemotherapy_use?.value
      if(typeof this.form.accident_indication === 'object') this.form.accident_indication = this.form.accident_indication?.value
      if (this.form.authorized_accommodation_type) this.form.authorized_accommodation_type = this.form.authorized_accommodation_type?.value

      if (
        this.form?.professional_selected
        && Object.keys(this.form.professional_selected).length
      ) this.form.professional_id = this.form.professional_selected?.value
    },

    async createGuide() {
      if(!this.isValidForm()) return;

      const isLoading = this.$loading.show();
      try {
        this.form.cid_1 = this.form.cid_1_selected?.value ?? null;
        this.form.cid_2 = this.form.cid_2_selected?.value ?? null;
        this.form.cid_3 = this.form.cid_3_selected?.value ?? null;
        this.form.cid_4 = this.form.cid_4_selected?.value ?? null;

        this.getSelectedValue()
        this.form.patient_id = this.patient.id

        const response = await this.api.createHospitalizationRequestGuide(this.form);
        this.$toast.success('Guia de solicitação de internação adicionada com sucesso!');
        this.closeModal()
        this.$emit('refresh-tiss-guides', { 'id' :response.data.id , 'guide_id': this.tissGuide.id })
        this.getHospitalizationGuidesNumbers()
      } catch (error) {
        this.$toast.error(error.message);
      } finally {
        isLoading.hide();
      }
    },
    async updateGuide() {
      const isLoading = this.$loading.show();
      try {
        this.getSelectedValue()

        this.form.cid_1 = this.form.cid_1_selected ? this.form.cid_1_selected.value : null;
        this.form.cid_2 = this.form.cid_2_selected ? this.form.cid_2_selected.value : null;
        this.form.cid_3 = this.form.cid_3_selected ? this.form.cid_3_selected.value : null;
        this.form.cid_4 = this.form.cid_4_selected ? this.form.cid_4_selected.value : null;

        const response = await this.api.updateHospitalizationRequestGuide(this.tissGuide.guide.id, this.form);
        this.$toast.success('Guia de solicitação de internação atualizada com sucesso!');
        this.closeModal()
        this.$emit('refresh-tiss-guides', { 'id' :response.data.id , 'guide_id': this.tissGuide.id })
      } catch (error) {
        this.$toast.error(error.message);
      } finally {
        isLoading.hide();
      }
    },
    async getCidById(id, field) {
      try {
        const response = await this.api.getCidById(id)
        if (Object.keys(response.data).length) {
          this.cids = [
            {
              label: `${response?.data?.code} - ${response.data?.description}`,
              value: response.data.id
            }
          ]
        }

        if(field === 'cid_1') this.form.cid_1_selected = this.cids.find(el => el.value === this.form.cid_1)
        else if(field === 'cid_2') this.form.cid_2_selected = this.cids.find(el => el.value === this.form.cid_2)
        else if(field === 'cid_3') this.form.cid_3_selected = this.cids.find(el => el.value === this.form.cid_3)
        else if(field === 'cid_4') this.form.cid_4_selected = this.cids.find(el => el.value === this.form.cid_4)

      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    selectCid10() {
      this.cids = []
    },
    clearForm() {
      this.form = this.getDefaultForm();
      this.validated = false
    },
    closeModal() {
      this.clearForm();
      this.$emit('onHide');
      this.$bvModal.hide('hospitalization_request_guide_modal');
    },
    getPatientWallet () {
      if (this.tissGuide?.id) return

      const personPlan = this.patient?.person_health_plans?.find(
        plan => plan?.health_plan_id === this.healthPlan?.id
      )

      this.form.wallet_number = personPlan?.registration
      this.form.wallet_expiration_date =  this.moment(personPlan?.valid_until).toDate()
    },
    selectContracted(value) {
      this.form.contractor_name = value?.label
      this.form.operator_code = value?.operator_code
      this.form.contracted_code_type = {
        label: value?.code_type,
        value: value?.code_type
      }
    },
  },
  watch: {
    tissGuide: {
      async handler(newValue) {
        if (newValue.guide_type === "App\\Models\\TissGuide\\TissGuideHospitalizationRequest") {
          this.form = {
            ...this.form,
            ...newValue.guide
          }

          this.form.provider_guide = newValue.provider_guide
          this.form.invoicing_tiss_setting_id = newValue.invoicing_tiss_setting_id
          this.form.tiss_invoice_id = newValue.tiss_invoice_id
          this.form.tiss_guide_id = newValue.id
          this.form.materialsProcedures = newValue.materials_procedures
          this.form.situation = newValue.situation
          this.form.authorization_date = this.moment(newValue.authorization_date).toDate()
          this.form.password_expiration_date = this.moment(this.form.password_expiration_date).toDate()
          this.form.wallet_expiration_date = this.moment(this.form.wallet_expiration_date).toDate()
          this.form.suggested_date_hospitalization = this.moment(this.form.suggested_date_hospitalization).toDate()
          this.form.probable_date_hospital_admission = this.moment(this.form.probable_date_hospital_admission).toDate()
          this.form.request_date = this.moment(this.form.request_date).toDate()

          this.form.contracted_code_type = this.contractedCodeTypes.find(option => option.value === newValue.guide.contracted_code_type)
          this.form.professional_council = this.professionalCouncils.find(option => option.value === newValue.guide.professional_council)
          this.form.uf = this.statesUf.find(option => option.value === newValue.guide.uf)
          this.form.cbo_code = this.cbos.find(option => option.value === newValue.guide.cbo_code)
          this.form.attendance_character = this.attendancesCharacter.find(option => option.value === newValue.guide.attendance_character)
          this.form.hospitalization_type = this.hospitalizationTypes.find(option => option.value === newValue.guide.hospitalization_type)
          this.form.internment_regime = this.internmentRegimes.find(option => option.value === newValue.guide.internment_regime)
          this.form.opme_usage_forecast = this.yesOrNo.find(option => option.value === !!newValue.guide.opme_usage_forecast)
          this.form.prediction_chemotherapy_use = this.yesOrNo.find(option => option.value === !!newValue.guide.prediction_chemotherapy_use)
          this.form.accident_indication = this.accidentIndications.find(option => option.value === newValue.guide.accident_indication)
          this.form.authorized_accommodation_type = this.accommodationsType.find(option => option.value === newValue.guide.authorized_accommodation_type)

          await this.getCidById(this.form.cid_1, 'cid_1');
          await this.getCidById(this.form.cid_2, 'cid_2');
          await this.getCidById(this.form.cid_3, 'cid_3');
          await this.getCidById(this.form.cid_4, 'cid_4');
        } else {
          this.clearForm()
        }
      },
      immediate: true
    },
    'invoicing_tiss_setting': {
      handler() {
        if (!this.tissGuide?.id) {
          this.setSelectsFields()
        }
      },
      deep: true,
      immediate: true
    },
    guideNumber() {
      if (!Object.keys(this.tissGuide).length) {
        this.form = this.getDefaultForm()
      }
    },
    'form.provider_guide': {
      handler(newValue) {
        if (newValue) {
          const form = {
            id: this.tissGuide.id,
            provider_guide: newValue,
            patient: {
              name: this.form.beneficiary_name
            }
          }
          this.$emit('update-form', { 'form': form, providerGuide: this.providerGuideUpdated ?? this.guideNumber})
        }
      },
      deep: true,
      immediate: true
    },
    patient: {
      handler(value) {
        if (value) {
          this.form.beneficiary_name = this.form.beneficiary_name || value.name
          this.form.newborn_care = this.form.newborn_care || this.patient?.birthday ? (this.moment(this.patient.birthday).add(1, 'M') >=  this.moment()) : false
          this.getPatientWallet()
        }
      },
      deep: true,
      immediate: true
    },
    providerGuideUpdated: {
      handler(newValue) {
        if (newValue) {
          this.form.provider_guide = newValue
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#hospitalization_request_guide_modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #525C7A;
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;
    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
  }

  label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin: 0;
  }

  .width-80 {
    width: 80%;
  }

  .width-95 {
    width: 95%;
  }

  .form-title{
    color:#304388;
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .check-disable {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-300) !important;
  }
}
</style>
